<template>
    <v-container class="pa-8">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
        <v-icon>mdi-card-account-details-outline</v-icon>
        File Maintenance
    </div>
    <v-tabs
        v-model="fileMaintenanceTab"
        background-color="grey lighten-4"
    >
        <v-tab
            v-for="item in fileMaintenanceTabItems"
            :key="item"
            active-class="primary lighten-5"
            style=" justify-content: center"
        >               
            {{ item }}
        </v-tab>
    </v-tabs>  

    <!-- DIALOG   -->
    <v-dialog
      v-model="multiDialog"
      persistent
      max-width="500"
    >
        <v-card>
            <v-card-title class="headline">
                <span>{{multiEdit ? 'Update' : 'Add'}} {{category[tab].replace(/([A-Z])/g, ' $1').trim()}}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="multiDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>  
            <v-card-text class="mt-4" v-if="multiEdit == false">
                <div v-for="multi in multiInput.filter(a=>{ return a.category == category[tab]})" :key="multi.category">
                    <v-text-field
                        v-for="(input,index) in multi.inputs"
                        :key="input"
                        :type="multi.inputType[index]"
                        :label="input.replace(/([A-Z])/g, ' $1').trim()"
                        :placeholder="`Enter ${input.replace(/([A-Z])/g, ' $1').trim()}`"
                        outlined
                        v-model="multi.data[index]"
                    ></v-text-field>

                    <div v-show="multi.arrayInputs !== undefined" v-for="arrName in multi.arrayInputs" :key="arrName" class="my-4">
                        <span class="text-body-1 font-weight-bold">
                        <v-btn
                            @click="addInput(arrName)"
                            class="float-right mr-1 add-button-position"
                            fab
                            x-small
                            color="primary"
                            >
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                        {{arrName == 'HSS' ? 'Health, Safefy and Security' : arrName }}
                        </span>
                        
                        <v-text-field 
                            v-for="(field, index) in returnEvalData(arrName)" 
                            :key="index" 
                            :placeholder="index !== 0 ? `Input another ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}` : `Input a ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}`"
                            v-model="field.text"
                            :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                            @click:append="deleteInput(index, arrName)"
                            >
                        </v-text-field>                        
                    </div>
                </div>
            </v-card-text>  
            <v-card-text class="mt-4" v-else>
                    <v-text-field
                        v-show="selectedMulti && multi.key !== '.key'"
                        v-for="multi in selectedMulti"
                        :key="multi.value"
                        :type="multi.inputType"
                        :label="multi.key.replace(/([A-Z])/g, ' $1').trim()"
                        :placeholder="`Enter ${multi.key.replace(/([A-Z])/g, ' $1').trim()}`"
                        outlined
                        v-model="updateMulti[multi.key]"
                    ></v-text-field>
                    <div v-for="arrName in selectedMultiArray" :key="arrName.key">
                        <span class="text-body-1 font-weight-bold">
                        <v-btn
                            @click="addInput(arrName.key)"
                            class="float-right mr-1 add-button-position"
                            fab
                            x-small
                            color="primary"
                            >
                            <v-icon dark>
                                mdi-plus
                            </v-icon>
                        </v-btn>
                        {{arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key }}
                        </span>
                    <v-text-field
                        v-for="(field, index) in returnEvalData(arrName.key)" 
                        :key="index" 
                        :placeholder="index !== 0 ? `Input another ${arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key}` : `Input a ${arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key}`"
                        v-model="field.text"
                        :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                        @click:append="deleteInput(index, arrName.key)"
                    ></v-text-field>
                    </div>
            </v-card-text>
            <v-card-actions class="pa-2">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="multiDialog = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    v-if="multiEdit == false"
                    color="primary"
                    text
                    @click="multiDialog = false,addMultiColumn(category[tab])"
                >
                    Save
                </v-btn>
                <v-btn
                    v-else
                    color="primary"
                    text
                    @click="multiDialog = false,updateMultiColumn(category[tab])"
                >
                    Update
                </v-btn>
            </v-card-actions> 
        </v-card>   
    </v-dialog>

    <!-- DIALOG -->
    <v-tabs-items v-model="fileMaintenanceTab">
        <v-tab-item>
            <v-card flat class="px-3 pt-4">
                <v-row> 
                    <v-col class="col-4">
                        <v-tabs vertical v-model="tab">
                            <v-tab v-for="name in category" :key="name"  active-class="text-primary primary lighten-5">
                                <!-- <v-icon left>
                                    {{tab == idx ? 'mdi-arrow-right-bold-box-outline' : ''}}
                                </v-icon> -->
                                {{name.replace(/([A-Z])/g, ' $1').trim()}}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col class="col">
                        <v-row class="mb-6 px-4">
                            <v-spacer class="col"></v-spacer>
                            <v-btn color="primary" @click="addItem(category[tab]),multiEdit = false,selectedMulti = null">
                                <v-icon left>mdi-plus</v-icon>
                                add {{category[tab].replace(/([A-Z])/g, ' $1').trim()}}
                            </v-btn>
                        </v-row>
                        
                        <v-data-table
                            :headers="returnHeaders"
                            :items="returnItems"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>           
                        </v-data-table>            
                    </v-col>
                </v-row>
            </v-card>
        </v-tab-item>
        <v-tab-item>
            <v-card flat>
                <!-- IRA File Maintenance Component -->
                <IRAFileMaintenance/>
                <!-- IRA File Maintenance Component -->
            </v-card>
        </v-tab-item>
        <v-tab-item>
            <v-card flat class="px-3 pt-4">
                <v-row> 
                    <v-col class="col-4">
                        <v-tabs vertical v-model="PRMPtab">
                            <v-tab v-for="name in PRMPcategory" :key="name"  active-class="text-primary primary lighten-5">
                                <!-- <v-icon left>
                                    {{tab == idx ? 'mdi-arrow-right-bold-box-outline' : ''}}
                                </v-icon> -->
                                {{name.replace(/([A-Z])/g, ' $1').trim()}}
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col class="col">
                        <v-row class="mb-6 px-4">
                            <v-spacer class="col"></v-spacer>
                            <v-btn color="primary" @click="addItem(PRMPcategory[PRMPtab]),multiEdit = false,selectedMulti = null">
                                <v-icon left>mdi-plus</v-icon>
                                add {{PRMPcategory[PRMPtab].replace(/([A-Z])/g, ' $1').trim()}}
                            </v-btn>
                        </v-row>
                        
                        <v-data-table
                            :headers="returnHeadersPRMP"
                            :items="returnItemsPRMP"
                            :items-per-page="5"
                            class="elevation-1"
                        >
                            <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                class="mr-2"
                                @click="editItem(item,true)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item,true)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>           
                        </v-data-table>            
                    </v-col>
                </v-row>
            </v-card>            
        </v-tab-item>
    </v-tabs-items>
    </v-container>
</template>
<script>
import IRAFileMaintenance from '../components/IRAFileMaintenance'
export default {
    components: {
        IRAFileMaintenance
    },
    data: () => ({
        fileMaintenanceTab: 0,
        fileMaintenanceTabItems: ['ORMP','IRA','PRMP'],
        multiDialog: false,
            tab: 0,
        category: [
            'AffectedStakeholder',
            'RiskStrategy',
            'RiskTreatmentOwner',
            'LikelihoodLevel',
            'RiskImpactLevel',
        ],
        singleInput: [
            'AffectedStakeholder',   
            'RiskStrategy',
            'RiskTreatmentOwner'
        ],
        multiInput: [
            {
                category: 'LikelihoodLevel',
                inputs: ['Level','Frequency','Probability'],
                inputType: ['number','text','text'],
                data: []
            },
            {
                category: 'RiskImpactLevel',
                inputs: ['Level','NIAT','MGTACTION'],
                arrayInputs: ['Reputation','HSS','Environment','LegalCompliance','Operational'],
                inputType: ['number','text','text'],
                data: []
            },
        ],
        updateMulti: {},
        multiEdit: false,
        selectedMulti: null,
        selectedMultiArray: null,
        updateMultiKey: null,
        inputReputation: [
            {
                text: ''
            }
        ],
        inputHSS: [
            {
                text: ''
            }
        ],
        inputEnvironment: [
            {
                text: ''
            }
        ],
        inputLegalCompliance: [
            {
                text: ''
            }
        ],
        inputOperational: [
            {
                text: ''
            }
        ],
        //PRMP ADDITIONAL
        PRMPcategory: ['Product'],
        PRMPsingleInput: ['Product'],
        PRMPtab: 0
        //PRMP ADDITIONAL
    }),
    firestore(){
      return {
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel'),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        Product: this.$db.collection('Product')
      }
    },
    computed:{
        returnHeaders(){
            let category = this.category[this.tab]
            if(this.singleInput.includes(category)) { 
                let text = category.replace(/([A-Z])/g, ' $1').trim()
                return [
                    { text: text, sortable: true, value: `${category}` },
                    { text: 'Actions', sortable: false, value: 'actions' } // Important
                ] 
            } else {
                let filter = this.multiInput.filter(a=>{
                    return a.category == category
                })[0].inputs
                
                let cols = filter.map(a=>{
                    return { text: `${a}`, sortable: true, value: `${a}` }  
                })

                cols.push({ text: 'Actions', sortable: false, value: 'actions' })
                return cols
            } 
            // return []
        },
        returnItems(){
            let category = this.category[this.tab]
            if(!eval(`this.${category}`)) return []
            return eval(`this.${category}`)
        },
        returnAffectedStakeholders(){
            return this.AffectedStakeholder.map(a=>{
                return {
                    text: a.AffectedStakeholder,
                    value: a.AffectedStakeholder
                }
            })
        },
        returnHeadersPRMP(){
            let category = this.PRMPcategory[this.PRMPtab]
            let text = category.replace(/([A-Z])/g, ' $1').trim()
            return [
                { text: text, sortable: true, value: `${category}` },
                { text: 'Actions', sortable: false, value: 'actions' } // Important
            ] 

            // return []
        },
        returnItemsPRMP(){
            let category = this.PRMPcategory[this.PRMPtab]
            if(!eval(`this.${category}`)) return []
            return eval(`this.${category}`)
        },
    },
    methods:{
        returnEvalData(arrName){
            return eval(`this.input${arrName}`)
        },
        returnValueToInputsData(arrName,value){
            let map = value.map(a=>{
                return {
                    text: a
                }
            })
            this[`input${arrName}`] = map
            return eval(`this.input${arrName}`)
        },
        addInput(arrName){
            this[`input${arrName}`].push({text: ''})
        },
        deleteInput(index,arrName){
            this[`input${arrName}`].splice(index, 1)
        },
        mapInput(arrName){
            return this[`input${arrName}`].map(function(value) {
                return value['text']
            })
        },
        async addItem(category){
            if(this.singleInput.includes(category) || this.PRMPsingleInput.includes(category)){
                await this.addSingleColumn(category)
            } else {
                this.multiDialog = true
            }
        },
        async editItem(item,prmp = false){
            let self = this
            let category = prmp ? this.PRMPcategory[this.PRMPtab] : this.category[this.tab]

            if(this.singleInput.includes(category) || this.PRMPsingleInput.includes(category)){
                await this.addSingleColumn(category,item[`${category}`],true,item['.key'])
            } else {
                this.multiDialog = true
                this.multiEdit = true
                
                const objUpdate = {...item}
                this.updateMultiKey = objUpdate['.key']
                delete objUpdate['.key']
                this.updateMulti = objUpdate
                

                let map = this.$lodash.map(Object.entries(objUpdate),
                function(value){
                    return {
                        key: value[0],
                        value: value[1],
                        inputType: self.returnType(value[0])
                    }
                })   

               
                this.selectedMulti = map.filter(a=>{
                    return a.key !== 'Environment' && a.key !== 'HSS' &&  a.key !== 'LegalCompliance' &&  a.key !== 'Operational' &&  a.key !== 'Reputation'
                })

                this.selectedMultiArray = map.filter(a=>{
                    return a.key !== '.key' && a.key == 'Environment' || a.key == 'HSS' ||  a.key == 'LegalCompliance' ||  a.key == 'Operational' ||  a.key == 'Reputation'
                })

                this.selectedMultiArray.forEach(a => {
                    this.returnValueToInputsData(a.key,a.value)
                })   

            }

        },
        async deleteItem(item,prmp = false){
            let category = prmp ? this.PRMPcategory[this.PRMPtab] : this.category[this.tab]
            let self = this

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this item?`,
                title: `Confirm Delete ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(confirm){
                 self.$db.collection(`${category}`).doc(item['.key']).delete()
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:`${category}`,documentKey:item['.key'],module: 'File Maintenance',action: `Deleted ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})  
                    self.$dialog.notify.success(`Delete Item from ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
        },
        async addSingleColumn(category,itemValue = null,isEdit = false,itemKey = null){
   
            let self = this
            self.multiDialog = false
            self.selectedMulti = null
            self.multiEdit = false  
            let res = await this.$dialog.prompt({
                text: `${isEdit ? 'Input New Value' : 'Input New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`,
                title: `${isEdit ? 'Update' : 'New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`,
                value: isEdit ? itemValue : ''
            })

            let newItem = {}
            newItem[`${category}`] = res

            if(!res) {
                this.$dialog.notify.warning('Cancelled / Empty Input',{
                    position: 'bottom-right',
                    timeout: 3000     
                })             
                return
            }
            
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to ${isEdit ? 'update' : 'save'} ${res}?`,
                title: `Confirm ${isEdit ? 'New Value' : 'New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(confirm){
                if(isEdit){
                    self.$db.collection(`${category}`).doc(itemKey).set(newItem)
                    .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:`${category}`,documentKey:itemKey,module: 'File Maintenance',action: `Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})  
                        self.$dialog.notify.success(`Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })                    
                } else {
                    self.$db.collection(`${category}`).add(newItem)
                    .then(async(doc)=>{
                        let id = doc.id
                        await self.$store.dispatch('logs/createNotifs', {collection:`${category}`,documentKey:id,module: 'File Maintenance',action: `Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`})  

                        self.$dialog.notify.success(`Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                }

            }
        },
        async addMultiColumn(category){
            let self = this
            let filter = this.multiInput.filter(a=>{
                return a.category == category
            })[0]

            let data = filter.data
            let inputs = filter.inputs
            let newItem = {}

            for(var x=0;x < data.length; x++){
                if(data[x] == '' || data[x] == null || data[x] == undefined){
                    this.$dialog.notify.warning('Empty Input',{
                        position: 'bottom-right',
                        timeout: 3000     
                    }) 
                    this.multiDialog = true
                    return
                }
                newItem[`${inputs[x]}`] = data[x]
            }

            if(filter.arrayInputs){
                let arrayInputs = filter.arrayInputs
                for(var z=0;z < arrayInputs.length; z++){
                    let mapArrayData = this.mapInput(arrayInputs[z])
                    newItem[`${arrayInputs[z]}`] = mapArrayData
                }                
            }

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new Item?`,
                title: `Confirm New ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(confirm){
                 self.$db.collection(`${category}`).add(newItem)
                 .then(async(doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:`${category}`,documentKey:id,module: 'File Maintenance',action: `Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`})  
                    self.$dialog.notify.success(`Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })      
                    self.multiDialog = false  
                    self.multiInput[0].data = []
                    self.multiInput[1].data = []
                    self.inputReputation= [
                        {
                            text: ''
                        }
                    ]
                    self.inputHSS= [
                        {
                            text: ''
                        }
                    ]
                    self.inputEnvironment= [
                        {
                            text: ''
                        }
                    ]
                    self.inputLegalCompliance= [
                        {
                            text: ''
                        }
                    ]
                    self.inputOperational= [
                        {
                            text: ''
                        }
                    ]                                 
                 })
            }
        },
        async updateMultiColumn(category){

            let itemKey = this.updateMultiKey
            let self = this
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to update this item?`,
                title: `Confirm Update ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(category == 'RiskImpactLevel'){
                let filter = this.multiInput.filter(a=>{
                    return a.category == category
                })[0]
                if(filter.arrayInputs){
                    let arrayInputs = filter.arrayInputs
                    for(var z=0;z < arrayInputs.length; z++){
                        let mapArrayData = this.mapInput(arrayInputs[z])
                        this.updateMulti[`${arrayInputs[z]}`] = mapArrayData
                    }                
                }
            }

            if(confirm){
                 self.$db.collection(`${category}`).doc(itemKey).set(this.updateMulti)
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:`${category}`,documentKey:itemKey,module: 'File Maintenance',action: `Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})   
                    self.$dialog.notify.success(`Updated  ${category.replace(/([A-Z])/g, ' $1').trim()} Item`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })      
                    self.multiDialog = false
                    self.selectedMulti = null
                    self.multiEdit = false  
                    self.updateMultiKey = null  
                    self.updateMulti = null 
                    self.inputReputation= [
                        {
                            text: ''
                        }
                    ]
                    self.inputHSS= [
                        {
                            text: ''
                        }
                    ]
                    self.inputEnvironment= [
                        {
                            text: ''
                        }
                    ]
                    self.inputLegalCompliance= [
                        {
                            text: ''
                        }
                    ]
                    self.inputOperational= [
                        {
                            text: ''
                        }
                    ] 
                 })
            }       
        },
        returnType(multi){
            let category = this.category[this.tab]
            let filter = this.multiInput.filter(a=>{
                return a.category == category
            })[0]
            let index = this.$lodash.findIndex(filter.inputs,a=>{
                return multi == a
            })
            return filter.inputType[index]
        }
    }

}
</script>
<style scoped>

div .v-tab  {
    justify-content: left;
}

</style>
