<template>
    <v-container>
        <!-- DIALOG -->
        <v-dialog
        v-model="multiDialog"
        persistent
        max-width="500"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>{{multiEdit ? 'Update' : 'Add'}} {{category[tab].replace(/([A-Z])/g, ' $1').trim()}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="multiDialog = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>  
                <v-card-text class="mt-4" v-if="multiEdit == false">
                    <div v-for="multi in multiInput.filter(a=>{ return a.category == category[tab]})" :key="multi.category">
                        <v-textarea
                            v-for="(area,index) in multi.areaInputs"
                            :key="area"
                            v-model="multi.areaData[index]"
                            :label="`Enter ${area.replace(/([A-Z])/g, ' $1')}`"
                            full-width
                            single-line
                            outlined
                        ></v-textarea>


                        <v-text-field
                            v-for="(input,index) in multi.inputs"
                            :key="input"
                            :type="multi.inputType[index]"
                            :label="input.replace(/([A-Z])/g, ' $1').trim()"
                            :placeholder="`Enter ${input.replace(/([A-Z])/g, ' $1').trim()}`"
                            outlined
                            v-model="multi.data[index]"
                        ></v-text-field>



                        <div v-for="(select,idx) in multi.selectInputs" :key="select+idx">
                            <v-select
                                :items="returnSelectData(select)"
                                outlined
                                v-show="returnSelectData(select)"
                                :multiple="select == 'Scoring'"
                                :chips="select == 'Scoring'"
                                :item-value="select == 'Scoring' ? `Label` : ''"
                                v-model="multi.selectData[idx]"
                                :label="select.replace(/([A-Z])/g, ' $1').trim()"
                                :placeholder="`Select ${select.replace(/([A-Z])/g, ' $1').trim()}`"
                            ></v-select>

                        </div>
                        

                        <div v-show="multi.arrayInputs !== undefined" v-for="arrName in multi.arrayInputs" :key="arrName" class="my-4">
                            <div v-if="arrName == 'AdditionalFields' && multi.selectData[1] !== null && multi.selectData[1] == 'PHP'">
                            <span class="text-body-1 font-weight-bold">
                            <v-btn
                                @click="addInput(arrName)"
                                class="float-right mr-1 add-button-position"
                                fab
                                x-small
                                color="primary"
                                >
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                            {{arrName == 'HSS' ? 'Health, Safefy and Security' : arrName }}
                            </span>

                            
                                <v-text-field 
                                    v-for="(field, index) in returnEvalData(arrName)" 
                                    :key="index" 
                                    :placeholder="index !== 0 ? `Input another ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}` : `Input a ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}`"
                                    v-model="field.text"
                                    :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                                    @click:append="deleteInput(index, arrName)"
                                    >
                                </v-text-field>   
                            </div>
                            <div v-else-if="arrName !== 'AdditionalFields'">
                                <span class="text-body-1 font-weight-bold">
                                <v-btn
                                    @click="addInput(arrName)"
                                    class="float-right mr-1 add-button-position"
                                    fab
                                    x-small
                                    color="primary"
                                    >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                                {{arrName == 'HSS' ? 'Health, Safefy and Security' : arrName }}
                                </span>
                                <v-text-field 
                                    v-for="(field, index) in returnEvalData(arrName)" 
                                    :key="index" 
                                    :placeholder="index !== 0 ? `Input another ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}` : `Input a ${arrName == 'HSS' ? 'Health, Safefy and Security' : arrName}`"
                                    v-model="field.text"
                                    :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                                    @click:append="deleteInput(index, arrName)"
                                    >
                                </v-text-field>   
                            </div>
                            
                     
                        </div>
                    </div>
                </v-card-text>  
                <v-card-text class="mt-4" v-else>
                        <v-text-field
                            v-show="selectedMulti && multi.key !== '.key'"
                            v-for="multi in selectedMulti"
                            :key="multi.value"
                            :type="multi.inputType"
                            :label="multi.key.replace(/([A-Z])/g, ' $1').trim()"
                            :placeholder="`Enter ${multi.key.replace(/([A-Z])/g, ' $1').trim()}`"
                            outlined
                            v-model="updateMulti[multi.key]"
                        ></v-text-field>

                        <v-textarea
                            v-show="selectedMultiArea"
                            v-for="area in selectedMultiArea"
                            :key="area.key"
                            :label="`Enter ${area.key.replace(/([A-Z])/g, ' $1')}`"
                            v-model="updateMulti[area.key]"
                            outlined
                        ></v-textarea>

                        <div v-for="(select,idx) in selectedMultiSelect" :key="select.key+idx">
                            <v-select
                                :items="returnSelectData(select.key)"
                                outlined
                                :multiple="select.key == 'Scoring'"
                                :chips="select.key == 'Scoring'"
                                v-show="returnSelectData(select.key)"
                                :item-value="select.key == 'Scoring' ? `Label` : ''"
                                v-model="updateMulti[select.key]"
                                :label="select.key.replace(/([A-Z])/g, ' $1').trim()"
                                :placeholder="`Select ${select.key.replace(/([A-Z])/g, ' $1').trim()}`"
                            ></v-select>

                        </div>

                        <div v-for="arrName in selectedMultiArray" :key="arrName.key">
                            <span class="text-body-1 font-weight-bold">
                            <v-btn
                                @click="addInput(arrName.key)"
                                class="float-right mr-1 add-button-position"
                                fab
                                x-small
                                color="primary"
                                >
                                <v-icon dark>
                                    mdi-plus
                                </v-icon>
                            </v-btn>
                            {{arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key }}
                            </span>
                        <v-text-field
                            v-for="(field, index) in returnEvalData(arrName.key)" 
                            :key="index" 
                            :placeholder="index !== 0 ? `Input another ${arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key}` : `Input a ${arrName.key == 'HSS' ? 'Health, Safefy and Security' : arrName.key}`"
                            v-model="field.text"
                            :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
                            @click:append="deleteInput(index, arrName.key)"
                        ></v-text-field>
                        </div>
                </v-card-text>
                <v-card-actions class="pa-2">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="multiDialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="multiEdit == false"
                        color="primary"
                        text
                        @click="multiDialog = false,addMultiColumn(category[tab])"
                    >
                        Save
                    </v-btn>
                    <v-btn
                        v-else
                        color="primary"
                        text
                        @click="multiDialog = false,updateMultiColumn(category[tab])"
                    >
                        Update
                    </v-btn>
                </v-card-actions> 
            </v-card>   
        </v-dialog>

        <!-- DIALOG -->


        <v-row> 
            <v-col class="col-3">
                <v-tabs vertical v-model="tab">
                    <div class="text-h6 ml-2">Inherent Risk</div>
                    <v-tab v-for="name in category.filter((a,index)=>{ return categorySection[index] == 'Inherent Risk'})" :key="name"  id="textLeft" active-class="text-primary primary lighten-5">
                        <!-- <v-icon left>
                            {{tab == idx ? 'mdi-arrow-right-bold-box-outline' : ''}}
                        </v-icon> -->
                        {{name.replace(/([A-Z])/g, ' $1').trim()}} <span class="text-caption ml-2"></span>
                    </v-tab>
                    <div class="text-h6 ml-2 mt-2">Control Category</div>
                    <v-tab v-for="name in category.filter((a,index)=>{ return categorySection[index] == 'Control Category'})" :key="name+'CC'"  id="textLeft" active-class="text-primary primary lighten-5">
                        <!-- <v-icon left>
                            {{tab == idx ? 'mdi-arrow-right-bold-box-outline' : ''}}
                        </v-icon> -->
                        {{name.replace(/([A-Z])/g, ' $1').trim()}} <span class="text-caption ml-2"></span>
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col class="col">
                <v-row class="mb-6 px-4">
                    <v-spacer class="col"></v-spacer>
                    <v-btn color="primary" @click="addItem(category[tab]),multiEdit = false,selectedMulti = null">
                        <v-icon left>mdi-plus</v-icon>
                        add {{category[tab].replace(/([A-Z])/g, ' $1').trim()}}
                    </v-btn>
                </v-row>
                
                <v-data-table
                    :headers="returnHeaders"
                    :items="returnItems"
                    :items-per-page="5"
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                        mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                    </template>           
                </v-data-table>            
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        multiDialog: false,
        tab: 0,
        category: [
            'Risks',
            'Department',
            'RiskIndicators',
            'RiskScoring',
            'Risks',
            'Scoring',
            'ControlIndicators',
        ],
        categorySection: [
            'Inherent Risk',
            'Inherent Risk',
            'Inherent Risk',
            'Inherent Risk',
            'Control Category',
            'Control Category',
            'Control Category',
        ],
        singleInput: [
            'Risks',   
            'Department',
        ],
        multiInput: [
            {
                category: 'RiskIndicators',
                areaInputs: ['Indicator','Rationale'],
                inputs: ['PercentageDividendName','PercentageDivisorName'],
                inputType: ['text','text'],
                selectInputs: ['Risks','ThresholdType'],
                arrayInputs: ['AdditionalFields'],
                data: [],
                selectData: [null,null],
                areaData: [],
                categorySection: 'Inherent Risk'
            },
            {
                category: 'RiskScoring',
                inputs: ['Label'],
                arrayInputs: ['EquivalentScore'],
                inputType: ['text'],
                data: [],
                categorySection: 'Inherent Risk'
            },
            {
                category: 'ControlIndicators',
                areaInputs: ['Control','Rationale'],
                inputs: [],
                selectInputs: ['Risks','Scoring','Section'],
                arrayInputs: ['ControlList','Metric'],
                inputType: [],
                data: [],
                areaData: [],
                selectData: [null,null,null],
                categorySection: 'Control Category'
            },
            {
                category: 'Scoring',
                inputs: ['Label'],
                arrayInputs: ['EquivalentScore'],
                inputType: ['text'],
                data: [],
                categorySection: 'Control Category'
            },
        ],
        updateMulti: {},
        multiEdit: false,
        selectedMulti: null,
        selectedMultiArray: null,
        selectedMultiArea: null,
        selectedMultiSelect: null,
        updateMultiKey: null,
        selectThresholdTypeIR: ['Percentage','PHP'],
        selectSectionCC: ['PEOPLE','PROCESS & TECHNOLOGY'],
        inputEquivalentScore: [
            {
                text: ''
            }
        ],
        inputAdditionalFields: [
            {
                text: ''
            }
        ],
        inputControlList: [
            {
                text: ''
            }
        ],
        inputMetric: [
            {
                text: ''
            }
        ],
        inputOperational: [
            {
                text: ''
            }
        ]        
    }),
    firestore(){
      return {
        RisksIR: this.$db.collection('Risks-IR'),
        RisksCC: this.$db.collection('Risks-CC'),
        DepartmentIR: this.$db.collection('Department-IR'),
        RiskIndicatorsIR: this.$db.collection('RiskIndicators-IR'),
        ControlIndicatorsCC: this.$db.collection('ControlIndicators-CC'),
        RiskScoringIR: this.$db.collection('RiskScoring-IR'),
        ScoringCC: this.$db.collection('Scoring-CC'),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel')
      }
    },
    computed:{
        selectRisksIR(){
            return this.RisksIR.map(a=>{
                return {
                    text: a.Risks,
                    value: a.Risks
                }
            })
        },
        selectRisksCC(){
            return this.RisksCC.map(a=>{
                return {
                    text: a.Risks,
                    value: a.Risks
                }
            })
        },
        selectScoringCC(){
            return this.ScoringCC.map(a=>{
                return {
                    text: `${a.Label} ${a.EquivalentScore[0]}`,
                    value: a
                }
            })
        },
        returnHeaders(){
            let category = this.category[this.tab]
            if(this.singleInput.includes(category)) { 
                let text = category.replace(/([A-Z])/g, ' $1').trim()
                return [
                    { text: text, sortable: true, value: `${category}` },
                    { text: 'Actions', sortable: false, value: 'actions' } // Important
                ] 
            } else {
                let filter = this.multiInput.filter(a=>{
                    return a.category == category && a.categorySection == this.categorySection[this.tab]
                })[0]

                let select = filter.selectInputs ?? []
                let inputs = filter.inputs ?? []
                let array = []
                if(category == 'ControlIndicators'){
                    array = ['ControlList']
                } else if (category == 'RiskIndicators'){
                    array = []
                } else {
                     array = filter.arrayInputs ?? []
                }
                

                let concat = [...select,...inputs,...array]
                
                let cols = concat.map(a=>{
                        return { text: `${a.replace(/([A-Z])/g, ' $1').trim()}`, sortable: true, value: `${a}` }  
                    })   



                console.log(cols,'cols')
                cols.push({ text: 'Actions', sortable: false, value: 'actions' })
                return cols
            } 
            // return []
        },
        returnItems(){
            let additionalSection = this.categorySection[this.tab] == 'Inherent Risk' ? 'IR' : 'CC'
            let category = this.category[this.tab]+additionalSection
            console.log(category,'cateogry')
            if(!eval(`this.${category}`)) return []
            return eval(`this.${category}`)
        },
        returnAffectedStakeholders(){
            return this.AffectedStakeholder.map(a=>{
                return {
                    text: a.AffectedStakeholder,
                    value: a.AffectedStakeholder
                }
            })
        }
    },
    methods:{
        returnSelectData(arrName){
            let additionalSection = this.categorySection[this.tab] == 'Inherent Risk' ? 'IR' : 'CC'
            return eval(`this.select${arrName}${additionalSection}`)
        },
        returnEvalData(arrName){
            return eval(`this.input${arrName}`)
        },
        returnValueToInputsData(arrName,value){
            let map = value.map(a=>{
                return {
                    text: a
                }
            })
            this[`input${arrName}`] = map
            return eval(`this.input${arrName}`)
        },
        addInput(arrName){
            this[`input${arrName}`].push({text: ''})
        },
        deleteInput(index,arrName){
            this[`input${arrName}`].splice(index, 1)
        },
        mapInput(arrName){
            return this[`input${arrName}`].map(function(value) {
                return value['text']
            })
        },
        async addItem(category){
            if(this.singleInput.includes(category)){
                await this.addSingleColumn(category)
            } else {
                this.multiDialog = true
            }
        },
        async editItem(item){
            let self = this
            let category = this.category[this.tab]

            if(this.singleInput.includes(category)){
                await this.addSingleColumn(category,item[`${category}`],true,item['.key'])
            } else {
                this.multiDialog = true
                this.multiEdit = true
                
                const objUpdate = {...item}
                this.updateMultiKey = objUpdate['.key']
                delete objUpdate['.key']
                this.updateMulti = objUpdate
                

                let map = this.$lodash.map(Object.entries(objUpdate),
                function(value){
                    return {
                        key: value[0],
                        value: value[1],
                        inputType: self.returnType(value[0])
                    }
                })   

               
                this.selectedMulti = map.filter(a=>{
                    return a.key !== 'AdditionalFields' && a.key !== 'EquivalentScore' &&  a.key !== 'ControlList' &&  a.key !== 'Metric' && a.key !== 'Indicator' &&  a.key !== 'Rationale' && a.key !== 'Control'  && a.key !== 'Risks' &&  a.key !== 'ThresholdType' && a.key !== 'Scoring' && a.key !== 'Section'
                })

                this.selectedMultiArray = map.filter(a=>{
                    return a.key !== '.key' && a.key == 'AdditionalFields' || a.key == 'EquivalentScore' ||  a.key == 'ControlList' ||  a.key == 'Metric'
                })

                this.selectedMultiArea = map.filter(a=>{
                    return a.key !== '.key' && a.key == 'Indicator' ||  a.key == 'Rationale' || a.key == 'Control' 
                })

                this.selectedMultiSelect = map.filter(a=>{
                    return a.key !== '.key' && a.key == 'Risks' ||  a.key == 'ThresholdType' || a.key == 'Scoring' || a.key == 'Section'
                })

                this.selectedMultiArray.forEach(a => {
                    this.returnValueToInputsData(a.key,a.value)
                })   

            }

        },
        async deleteItem(item){
            let category = this.category[this.tab]
            let self = this

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this item?`,
                title: `Confirm Delete ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(confirm){
                 let additionalSection = self.categorySection[self.tab] == 'Inherent Risk' ? 'IR' : 'CC'
                 self.$db.collection(`${category}-${additionalSection}`).doc(item['.key']).delete()
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:`${category}-${additionalSection}`,documentKey:item['.key'],module: 'File Maintenance',action: `Deleted ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})  
                    self.$dialog.notify.success(`Delete Item from ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
        },
        async addSingleColumn(category,itemValue = null,isEdit = false,itemKey = null){
   
            let self = this
            self.multiDialog = false
            self.selectedMulti = null
            self.multiEdit = false  
            let res = await this.$dialog.prompt({
                text: `${isEdit ? 'Input New Value' : 'Input New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`,
                title: `${isEdit ? 'Update' : 'New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`,
                value: isEdit ? itemValue : ''
            })

            let newItem = {}
            newItem[`${category}`] = res

            if(!res) {
                this.$dialog.notify.warning('Cancelled / Empty Input',{
                    position: 'bottom-right',
                    timeout: 3000     
                })             
                return
            }
            
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to ${isEdit ? 'update' : 'save'} ${res}?`,
                title: `Confirm ${isEdit ? 'New Value' : 'New'} ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            if(confirm){
                let additionalSection = self.categorySection[self.tab] == 'Inherent Risk' ? 'IR' : 'CC'
                if(isEdit){
                    self.$db.collection(`${category}-${additionalSection}`).doc(itemKey).set(newItem)
                    .then(async()=>{
                        await self.$store.dispatch('logs/createNotifs', {collection:`${category}-${additionalSection}`,documentKey:itemKey,module: 'File Maintenance',action: `Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})  
                        self.$dialog.notify.success(`Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })                    
                } else {
                    self.$db.collection(`${category}-${additionalSection}`).add(newItem)
                    .then(async(doc)=>{
                        let id = doc.id
                        await self.$store.dispatch('logs/createNotifs', {collection:`${category}-${additionalSection}`,documentKey:id,module: 'File Maintenance',action: `Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`})  

                        self.$dialog.notify.success(`Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })                     
                    })
                }

            }
        },
        async addMultiColumn(category){
            try {
                let self = this
                let filter = this.multiInput.filter(a=>{
                    return a.category == category
                })[0]
                console.log(filter,'filter')
                let data = filter.data
                let inputs = filter.inputs
                let select = filter.selectInputs ?? []
                let area = filter.areaInputs ?? []
                let newItem = {}

                select.forEach((a,index)=>{
                    newItem[`${a}`] = filter.selectData[index]
                })

                area.forEach((a,index)=>{
                    newItem[`${a}`] = filter.areaData[index]
                })

                for(var x=0;x < data.length; x++){
                    if(data[x] == '' || data[x] == null || data[x] == undefined){
                        this.$dialog.notify.warning('Empty Input',{
                            position: 'bottom-right',
                            timeout: 3000     
                        }) 
                        this.multiDialog = true
                        return
                    }
                    newItem[`${inputs[x]}`] = data[x]
                }

                if(filter.arrayInputs){
                    let arrayInputs = filter.arrayInputs
                    for(var z=0;z < arrayInputs.length; z++){
                        let mapArrayData = this.mapInput(arrayInputs[z]) == undefined ? null : this.mapInput(arrayInputs[z])
                        newItem[`${arrayInputs[z]}`] = mapArrayData
                    }                
                }

                const confirm = await this.$dialog.confirm({
                    text: `Do you really want to save this new Item?`,
                    title: `Confirm New ${category.replace(/([A-Z])/g, ' $1').trim()}`
                })

                if(confirm){
                    let additionalSection = self.categorySection[self.tab] == 'Inherent Risk' ? 'IR' : 'CC'
                    self.$db.collection(`${category}-${additionalSection}`).add(newItem)
                    .then(async(doc)=>{
                        let id = doc.id
                        await self.$store.dispatch('logs/createNotifs', {collection:`${category}-${additionalSection}`,documentKey:id,module: 'File Maintenance',action: `Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`})  
                        self.$dialog.notify.success(`Added New ${category.replace(/([A-Z])/g, ' $1').trim()}`, {
                            position: 'bottom-right',
                            timeout: 3000
                        })      
                        self.multiDialog = false  
                        self.multiInput[0].data = []
                        self.multiInput[1].data = []
                            self.inputEquivalentScore= [
                                {
                                    text: ''
                                }
                            ]
                            self.inputAdditionalFields= [
                                {
                                    text: ''
                                }
                            ]
                            self.inputEnvironment= [
                                {
                                    text: ''
                                }
                            ]
                            self.inputLegalCompliance= [
                                {
                                    text: ''
                                }
                            ]
                            self.inputOperational= [
                                {
                                    text: ''
                                }
                            ]                                 
                        })
                }                
            } catch (error) {
                console.log(error,'error')
            }

        },
        async updateMultiColumn(category){

            let itemKey = this.updateMultiKey
            let self = this
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to update this item?`,
                title: `Confirm Update ${category.replace(/([A-Z])/g, ' $1').trim()}`
            })

            let multiArray = ['RiskIndicators','RiskScoring','ControlIndicators','Scoring']

            if(multiArray.includes(category)){
                let filter = this.multiInput.filter(a=>{
                    return a.category == category
                })[0]
                if(filter.arrayInputs){
                    let arrayInputs = filter.arrayInputs
                    for(var z=0;z < arrayInputs.length; z++){
                        let mapArrayData = this.mapInput(arrayInputs[z])
                        this.updateMulti[`${arrayInputs[z]}`] = mapArrayData
                    }                
                }
            }

            console.log(this.updateMulti,'update sumthing')

            if(confirm){
                 let additionalSection = self.categorySection[self.tab] == 'Inherent Risk' ? 'IR' : 'CC'
                 self.$db.collection(`${category}-${additionalSection}`).doc(itemKey).set(this.updateMulti)
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:`${category}-${additionalSection}`,documentKey:itemKey,module: 'File Maintenance',action: `Updated ${category.replace(/([A-Z])/g, ' $1').trim()} Item`})   
                    self.$dialog.notify.success(`Updated  ${category.replace(/([A-Z])/g, ' $1').trim()} Item`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })      
                    self.multiDialog = false
                    self.selectedMulti = null
                    self.selectedMultiSelect = null
                    self.selectedMultiArea = null
                    self.multiEdit = false  
                    self.updateMultiKey = null  
                    self.updateMulti = null 
                    self.inputEquivalentScore= [
                        {
                            text: ''
                        }
                    ]
                    self.inputAdditionalFields= [
                        {
                            text: ''
                        }
                    ]
                    self.inputEnvironment= [
                        {
                            text: ''
                        }
                    ]
                    self.inputLegalCompliance= [
                        {
                            text: ''
                        }
                    ]
                    self.inputOperational= [
                        {
                            text: ''
                        }
                    ]  
                 })
            }       
        },
        returnType(multi){
            let category = this.category[this.tab]
            let filter = this.multiInput.filter(a=>{
                return a.category == category
            })[0]
            let index = this.$lodash.findIndex(filter.inputs,a=>{
                return multi == a
            })
            return filter.inputType[index]
        }
    }
}
</script>
<style scoped>

div .v-tab {
    justify-content: left;
}

</style>